/**
 * Theme: Shreyu - Responsive Bootstrap 4 Landing UI Kit
 * Author: Coderthemes
 * Module/App: Main Js
 */


!function($) {
    'use strict';

    var App = function () {
        this.$body = $('body'),
        this.$window = $(window)
    };

    App.prototype.initComponents = function() {
        // Popovers
        $(function () {
            $('[data-toggle="popover"]').popover()
        });

        // Tooltip
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        });
    },

    /**
     * Initilization
     */
    App.prototype.init = function () {

        // init components
        this.initComponents();

        // on window load
        $(window).on('load', function () {
        });

        // on window scroll
        $(window).on('scroll', function () {
            // responsive menu
            $('.navbar-toggle').on('click', function (event) {
                $(this).toggleClass('open');
                $('#navigation').slideToggle(400);
            });

            // menu dropdown
            $('.navigation-menu>li').slice(-1).addClass('last-elements');

            $('.menu-arrow,.submenu-arrow').on('click', function (e) {
                if ($(window).width() < 992) {
                    e.preventDefault();
                    $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
                }
            });

            // scroll to section
            // $('.navigation-menu a').on('click', function(event) {
            //     var $anchor = $(this);
            //     $('html, body').stop().animate({
            //         scrollTop: $($anchor.attr('href')).offset().top - 0
            //     }, 1500, 'easeInOutExpo');
            //     event.preventDefault();
            // });

            // TODO
            // if ($('#counter').length) {
            //     var oTop = $('#counter').offset().top - window.innerHeight;
            //     if ($(window).scrollTop() > oTop) {
            //         $('.counter-value').each(function () {
            //             var $this = $(this),
            //                 countTo = $this.attr('data-count');
            //             $({
            //                 countNum: $this.text()
            //             }).animate({
            //                 countNum: countTo
            //             },
            //                 {
            //                     duration: 2000,
            //                     easing: 'swing',
            //                     step: function () {
            //                         $this.text(Math.floor(this.countNum));
            //                     },
            //                     complete: function () {
            //                         $this.text(this.countNum);
            //                         //alert('finished');
            //                     }

            //                 });
            //         });
            //     }
            // }
        });
    },

    $.App = new App, $.App.Constructor = App
}(window.jQuery),

//initializing main application module
function ($) {
    "use strict";
    $.App.init();
}(window.jQuery);



// // filter
// $(window).on('load', function() {
//     var $container = $('.filterContainer');
//     var $filter = $('#filter');
//     $container.isotope({
//         filter: '*',
//         layoutMode: 'masonry',
//         animationOptions: {
//             duration: 750,
//             easing: 'linear'
//         }
//     });
//     $filter.find('a').click(function() {
//         var selector = $(this).attr('data-filter');
//         $filter.find('a').removeClass('active');
//         $(this).addClass('active');
//         $container.isotope({
//             filter: selector,
//             animationOptions: {
//                 animationDuration: 750,
//                 easing: 'linear',
//                 queue: false,
//             }
//         });
//         return false;
//     });
// });


// // ----- OWL CAROUSEL ----- //
// $("#owl-demo-1").owlCarousel({
//     autoPlay: 3000,
//     stopOnHover: true,
//     navigation: false,
//     paginationSpeed: 1000,
//     goToFirstSpeed: 2000,
//     singleItem: true
// });

// // Carousel
// $("#owl-demo").owlCarousel({
//     autoPlay: 3000, //Set AutoPlay to 3 seconds
//     items: 2,
//     itemsDesktop : [1024, 2],
//             itemsDesktopSmall : [768, 1],
//             itemsTablet: [568, 1]
// });

// // Carousel
// $("#owl-carousel").owlCarousel({
//     autoPlay: 3000,
//     stopOnHover: true,
//     navigation: false,
//     paginationSpeed: 1000,
//     goToFirstSpeed: 2000,
//     singleItem: true,
//     autoHeight: true,
// });